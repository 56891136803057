import { useRef } from "react";
import api from "../lib/api";
import axios from "axios";

export default function ReplyWrite({ itemId, refetch, attribute }) {
  const replyInput = useRef();

  const doSubmit = (e) => {
    e.preventDefault();

    if (
      !replyInput.current.value ||
      replyInput.current.value.replace(/\s/g, "") === ""
    ) {
      replyInput.current.value = "";
      replyInput.current.focus();
      return;
    }

    let formData = new FormData();
    // if (attribute.includes("FILE")) {
    //   let fileCount = fileInput.current.files.length;
    //   for (var x = 0; x < fileCount; x++) {
    //     formData.append(`file`, fileInput.current.files[x]);
    //   }
    // }
    let param = {
      content: replyInput.current.value,
      // contentType: `html`,
    };

    for (var key in param) formData.append(key, param[key]);

    axios
      .post(`/api/writeTalkComment/${itemId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + api.getToken(),
        },
      })
      .then((res) => {
        replyInput.current.value = "";
        // fileInput.current.value = "";
        refetch();
      })
      .catch((res) => {
        window.alert(res);
      });
  };

  const onKeyDown = (e) => {
    // if ((e.ctrlKey || e.metaKey) && (e.keyCode == 13 || e.keyCode == 10))
    if (e.shiftKey && (e.keyCode === 13 || e.keyCode === 10)) return;
    // if (e.keyCode === 13 || e.keyCode === 10) doSubmit(e);
    if (e.keyCode === 13 || e.keyCode === 10) return;
  };

  return (
    <>
      <tr className="border-t border-gray-200">
        <td className=" dark:bg-slate-800">
          <form onSubmit={doSubmit} className="my-2 mt-8">
            <div className="flex">
              {/* <div className="px-4 py-2 w-2/12 font-bold text-gray-700 dark:text-gray-200">
                {nick ? nick : name}
              </div> */}
              <div className="w-full">
                <div className="flex">
                  <textarea
                    required
                    ref={replyInput}
                    type="text"
                    name="content"
                    className="px-3 py-2 bg-white dark:bg-black dark:placeholder:text-gray-600 flex-1
          text-sm placeholder-slate-400
          focus:outline-none ring-1 ring-stone-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-gray-600 
          disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 disabled:shadow-none mr-0.5"
                    placeholder="댓글을 입력해주세요"
                    onKeyDown={onKeyDown}
                  />

                  <input
                    type="submit"
                    value="댓글 등록"
                    className="px-4
          text-sm font-semibold bg-gray-500 border-gray-500 dark:border-gray-700 border dark:bg-gray-700 text-white dark:text-gray-200
        hover:bg-stone-600 hover:cursor-pointer"
                  />
                </div>
                {/* {attribute.includes("FILE") && (
                  <>
                    <div className="flex my-8">
                      <label
                        htmlFor="file"
                        className="text-sm font-semibold text-slate-600 px-4 py-2 sr-only"
                      >
                        파일 첨부:
                      </label>

                      <input
                        type="file"
                        name="file"
                        ref={fileInput}
                        multiple
                        className="w-full text-sm text-gray-500 
          file:mr-4 file:py-2 file:px-4 file:border-0 
          file:text-sm file:font-semibold file:bg-gray-500 file:text-white
          hover:file:bg-stone-600 hover:file:cursor-pointer"
                      />
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </form>
        </td>
      </tr>
    </>
  );
}
