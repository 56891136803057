import axios from "axios";
import qs from "query-string";
import { Cookies } from "react-cookie";
import { api_address, login_address } from "./api_address";

class api {
  constructor() {
    this.cookies = new Cookies();
    axios.defaults.baseURL = api_address;
    axios.interceptors.request.use(
      function (config) {
        // 요청을 보내기 전에 수행할 일
        // console.log(config.url);
        // ...
        return config;
      },
      function (error) {
        // 오류 요청을 보내기전 수행할 일
        // ...
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // console.log(response);
        return response;
      },
      function (error) {
        // 오류 응답을 처리
        // ...
        return Promise.reject(error);
      }
    );
  }

  call(name, param, header) {
    return axios.post(name, qs.stringify(param), { headers: header });
  }

  isLogin() {
    if (this.cookies.get("accessToken") != null) return true;
    else return false;
  }

  getUserData() {
    return this.cookies.get("userData");
  }

  hasUserData() {
    if (this.cookies.get("userData") != null) return true;
    else return false;
  }

  getAuth() {
    if (this.cookies.get("authData")) return true;
    else return false;
  }

  getAuthData() {
    return this.cookies.get("authData");
  }

  getSocial() {
    if (this.cookies.get("socialData")) return true;
    else return false;
  }

  getSocialData() {
    return this.cookies.get("socialData");
  }

  getToken() {
    return this.cookies.get("accessToken");
  }

  login(code) {
    return new Promise((resolve, reject) => {
      this.call(
        `${login_address}/token`,
        {
          client_id: "senseworldclient",
          client_secret: "3169427c955811eeb75400e04cb73645",
          grant_type: "authorization_code",
          code,
        },
        {
          "Content-type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        }
      )
        .then((res) => {
          this.cookies.set("accessToken", res.data["access_token"], {
            path: "/",
            secure: true,
            maxAge: res.data["expire"],
            // httpOnly: true
          });
          this.cookies.set("refreshToken", res.data["refresh_token"], {
            path: "/",
            secure: true,
            maxAge: res.data["refresh_token_expire"],
          });

          this.apiCall(`${login_address}/me`, null, true)
            .then((res) => {
              this.cookies.set("userData", res.data, { path: "/" });
              resolve();
            })
            .catch((res) => {
              console.log(res);
              // reject(res);
            });
        })
        .catch((res) => {
          console.log(res);
          // reject(res);
        });
    });
  }

  async checkMe() {
    try {
      const response = await this.apiCall(`${login_address}/me`, null, true);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  logout() {
    this.cookies.remove("accessToken", { path: "/" });
    this.cookies.remove("authData", { path: "/" });
    this.cookies.remove("userData", { path: "/" });
    this.cookies.remove("socialData", { path: "/" });
    this.cookies.remove("refreshToken", { path: "/" });
  }

  apiCall(name, param, auth) {
    const headers = {
      "Content-type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
    if (auth === true)
      headers["Authorization"] = "bearer " + this.cookies.get("accessToken");
    return this.call(name, param, headers);
  }

  get(address) {
    return axios.get(this.getBaseURL() + address);
  }

  upload(name, param, file) {
    if (file === null) return this.apiCall(name, param, true);
    let formData = new FormData();
    if (param !== null) {
      for (var key in param) formData.append(key, param[key]);
    }
    if (file !== null) {
      for (var key in file) formData.append(key, file[key]);
    }
    return axios.post(name, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "bearer " + this.cookies.get("accessToken"),
      },
    });
  }
}

export default new api();
