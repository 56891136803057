import { useRecoilValue } from "recoil";
import { uploadStateAtom } from "../lib/atom";

export default function UploadModal({ upload }) {
  const uploadPercent = useRecoilValue(uploadStateAtom);
  return (
    <>
      <div
        className={`bg-gray-900/20 z-50 h-full w-full absolute top-0 left-0 ${
          upload ? null : `hidden`
        }`}
      ></div>
      <div
        className={`text-base font-normal pb-2 px-2 border border-stone-200 bg-white rounded-md absolute top-[50vh] left-[50vw] z-50 -translate-x-1/2 -translate-y-1/2 ${
          upload ? null : `hidden`
        }`}
      >
        업로드 중...
        <span className="font-bold text-2xl">{uploadPercent}</span>% 완료
      </div>
    </>
  );
}
