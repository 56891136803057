import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import qs from "query-string";
import { AuthContext } from "../lib/AuthProvider";

export default function Auth() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { login, setUser } = useContext(AuthContext);

  useEffect(() => {
    // const currentUrl = window.location.href;
    // const searchParams = new URL(currentUrl).searchParams;
    // const code = searchParams.get("code");
    const code = qs.parse(search).code;
    // console.log(code);
    if (code) {
      window.opener.postMessage({ code }, window.location.origin);
    }

    window.close();

    // });
  }, []);

  return <div className="text-center">Login 중 입니다...</div>;
}
