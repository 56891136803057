import { useRecoilValue } from "recoil";
import { nameAtom, nickAtom, tokenAtom } from "./lib/atom";
import { useContext, useEffect } from "react";
import { AuthContext } from "./lib/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const token = useRecoilValue(tokenAtom);
  const name = useRecoilValue(nameAtom);
  const nick = useRecoilValue(nickAtom);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!!location?.state?.from) {
      if (!!token) navigate(location.state.from);
    } else return;
  }, [token]);

  return (
    <>
      {token ? (
        <div className=" bg-blue-600 text-white h-22">
          <div className=" mx-auto flex lg:w-3/4">
            <div className="px-8 p-2 mr-auto lg:px-0">
              <div
                tabIndex={0}
                role="button"
                onClick={() => navigate("/")}
                className="font-bold pr-12 text-lg cursor-pointer"
              >
                SENSE WORLD home
              </div>
              <div>
                안녕하세요?{" "}
                <span className="text-yellow-300">
                  {nick ? nick + " ( " + name + " ) " : name}
                </span>
                님
              </div>
            </div>
            <div
              tabIndex={0}
              role="button"
              className="items-center flex px-8 font-bold rounded-md text-white  cursor-pointer hover:bg-yellow-300 hover:text-black hover:font-bold"
              onClick={() => {
                auth.logout();
                navigate("/");
              }}
            >
              로그아웃
            </div>
          </div>
        </div>
      ) : (
        <div className="flex bg-blue-600 text-white fixed top-0 w-full">
          <div className=" mx-auto flex w-full lg:w-3/4">
            <div className="px-16 p-4 mr-auto lg:px-0">
              <div
                tabIndex={0}
                role="button"
                onClick={() => navigate("/")}
                className="font-bold pr-12 text-lg cursor-pointer"
              >
                SENSE WORLD home
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
