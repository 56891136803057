import { useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../lib/api";

import UploadModal from "../components/UploadMordal";
import { CancelButton, SubmitButton } from "../components/Button";
import axios from "axios";
import qs from "query-string";

export default function BoardWrite() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const titleInput = useRef();
  const contentInput = useRef();
  const fileInput = useRef();

  const [upload, setUpload] = useState(false);

  const doSubmit = (e) => {
    e.preventDefault();

    if (!titleInput.current.value) {
      titleInput.current.focus();
      return;
    }
    if (!contentInput.current.value) {
      contentInput.current.focus();
      return;
    }

    if (location?.state?.attributes.includes("FILE")) {
      let formData = new FormData();
      let fileCount = fileInput.current.files.length;
      for (var x = 0; x < fileCount; x++) {
        formData.append(`file`, fileInput.current.files[x]);
      }
      let param = {
        title: titleInput.current.value,
        content: contentInput.current.value,
        // contentType: `html`,
      };

      for (var key in param) formData.append(key, param[key]);

      setUpload(true);

      axios
        .post(
          `/api/writeTalkPage/${params.boardId}`,
          formData,
          // qs.stringify({
          //   title: titleInput.current.value,
          //   content,
          //   contentType: `html`,
          //   // important,
          // }),
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          navigate(-1);
        })
        .catch((res) => {
          window.alert(res);
        });
    } else {
      setUpload(true);

      axios
        .post(
          `/api/writeTalkPage/${params.boardId}`,
          qs.stringify({
            title: titleInput.current.value,
            content: contentInput.current.value,
            // contentType: `html`,
            // important,
          }),
          {
            headers: {
              "Content-Type": "Application/json",
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          navigate(-1);
        })
        .catch((res) => {
          window.alert(res);
        });
    }
  };

  return (
    <>
      <UploadModal upload={upload} />
      <div className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-gray-200 mb-10">
        {location?.state?.title} 새 글 작성
      </div>
      <form onSubmit={doSubmit}>
        <label
          htmlFor="title"
          className="block text-sm font-semibold text-slate-600 mb-1"
        >
          제목:
        </label>
        <input
          autoFocus
          required
          type="text"
          name="title"
          ref={titleInput}
          className="block w-full px-3 py-2 bg-white 
          border border-slate-300 text-sm shadow-sm placeholder-slate-400
          focus:outline-none  focus:ring-1 focus:ring-sky-500 
          disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 disabled:shadow-none"
        />

        <br />
        <label
          htmlFor="content"
          className="block text-sm font-semibold text-slate-600 mb-1"
        >
          내용:
        </label>
        <textarea
          ref={contentInput}
          required
          type="text"
          name="content"
          className="block w-full h-48 px-3 py-2 bg-white 
          border border-slate-300 text-sm shadow-sm placeholder-slate-400
          focus:outline-none  focus:ring-1 focus:ring-sky-500 
          disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 disabled:shadow-none"
        />

        <br />
        <label
          htmlFor="file"
          className="block text-sm font-semibold text-slate-600 mb-1"
        >
          파일 첨부:
        </label>
        <input
          type="file"
          name="file"
          ref={fileInput}
          multiple
          className="block w-full text-sm text-gray-500 
          file:mr-4 file:py-2 file:px-4 file:border-0 
          file:text-sm file:font-semibold file:bg-gray-500 file:text-white
          hover:file:bg-stone-600 hover:file:cursor-pointer"
        />

        <div className="mt-4 flex justify-end space-x-4">
          <SubmitButton text={`완료`} />
          <CancelButton
            text={`취소`}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          />
        </div>
      </form>
    </>
  );
}
