import axios from "axios";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import api from "../lib/api";
import { printTime } from "../lib/print_text";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import SearchTypeBar from "../components/SearchTypeBar";
import qs from "query-string";
import ListTestPagination from "../components/ListTestPagination";

import { useRecoilValue } from "recoil";
import { tokenAtom } from "../lib/atom";
import { useEffect, useState } from "react";
import { BackButton, LinkButton } from "../components/Button";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export default function BoardMain({ state }) {
  const location = useLocation();
  const params = useParams();

  const [searchParams] = useSearchParams();
  const pageParams = parseInt(searchParams.get("page")) || 1;
  const keywordParams = searchParams.get("search") || "";
  const typeParams = searchParams.get("type") || 0;
  const sizeParams = searchParams.get("size") || 10;
  const accessToken = useRecoilValue(tokenAtom);

  const [title, setTitle] = useState();
  const [attr, setAttr] = useState();
  const [admin, setAdmin] = useState();
  const [msg, setMsg] = useState();

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [
      "selectBoard",
      params.boardId,
      pageParams,
      keywordParams,
      typeParams,
      sizeParams,
    ],
    queryFn: () => {
      setMsg("");
      return axios
        .post(
          `/api/getPageItemList/${params.boardId}`,
          qs.stringify({
            search: keywordParams,
            type: typeParams,
            page: pageParams - 1,
            size: sizeParams,
          }),
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          return res.data.result;
        })
        .catch((error) => {
          if (error.response.status === 406) {
            setMsg("접근 권한이 없습니다.");
          }
        });
    },
  });
  const cache = useQueryClient();
  useEffect(() => {
    // if (!location.state) {
    //   setTitle(location?.state?.boardTitle);
    //   setAttr(location?.state?.boardAttributes);
    //   setAdmin(location?.state?.admin);
    // } else {
    const BoardList = cache.getQueryData([
      "ChannelBoardList",
      params.channelId,
    ]);
    const currentBoard = BoardList.filter(
      (item) => item.id === Number(params.boardId)
    )[0];
    setTitle(currentBoard.name);
    setAttr(currentBoard.attributes);
    const ChannelInfo = cache.getQueryData(["ChannelInfo", params.channelId]);
    setAdmin(ChannelInfo.admin);
    // }
    // refetch();
  }, [location, refetch]);

  const itemList = data?.content?.map((row) => (
    <tr
      key={row.id}
      className={`font-medium leading-6 text-gray-800 dark:text-gray-400 hover:bg-gray-50 border-b-stone-200 border-b dark:hover:bg-gray-800 dark:border-b-gray-800`}
    >
      <td className="sm:text-ellipsis pl-4">
        <Link
          to={`/channel/${params.channelId}/${params.boardId}/item/${row.id}`}
          state={{ title: title, attributes: attr, admin: admin }}
        >
          {row.notice === 1 && `📌 `}
          {row.title}
          <span className="text-gray-400 text-xs">
            {row.count.commentCount !== 0 &&
              typeof row.count.commentCount === "number" &&
              " + " + row.count.commentCount}
          </span>
          {row.count.fileCount > 0 && (
            <span className="text-gray-400 text-xs">
              <PaperClipIcon className="h-4 inline-block pl-2 mb-1" />
            </span>
          )}
        </Link>
      </td>
      <td className="py-2 px-4 text-center text-gray-500 text-sm">
        {row.name}
      </td>
      <td className="py-2 px-4 text-center text-gray-500 text-sm">
        {row.count.readCount}
      </td>
      <td className="py-2 px-4 text-center text-gray-500 text-sm">
        {printTime(row.createTime, false)}
      </td>
    </tr>
  ));

  return (
    <>
      <div className="flex mb-10">
        <h1 className="text-2xl sm:text-3xl font-bold text-stone-900 dark:text-stone-200 ">
          {title}
        </h1>
        <div className="ml-auto">
          <SearchTypeBar />
        </div>
      </div>
      {isLoading ? (
        <div className="container flex items-center align-middle text-center">
          Loading...
        </div>
      ) : error ? (
        <div className="text-center mt-4">
          Error: {msg ? msg : error.message}
        </div>
      ) : (
        <div>
          <table className="my-4 mx-auto w-full">
            <thead className="bg-stone-200 dark:bg-slate-600">
              <tr className="border-b border-stone-300 dark:border-gray-400/20">
                <th className="py-2">제목</th>
                <th className="py-2 w-40 ">작성자</th>
                <th className="py-2 xl:w-28 w-16 ">조회수</th>
                <th className="py-2 w-32">작성일</th>
              </tr>
            </thead>
            <tbody>{itemList}</tbody>
          </table>
          <div className="flex justify-end">
            {!!accessToken && attr?.includes("WRITE") && (
              <LinkButton
                text={`새 글 쓰기`}
                address={`/channel/${params.channelId}/${params.boardId}/write`}
                state={{ title: location?.state?.title, attributes: attr }}
              />
            )}
          </div>
          <ListTestPagination data={data} />
        </div>
      )}
    </>
  );
}
