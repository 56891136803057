import axios from "axios";
import qs from "query-string";
import { useNavigate, useParams } from "react-router-dom";
import api from "../lib/api";
import { useRef, useState } from "react";
import { CancelButton, SubmitButton } from "../components/Button";
import { PaperClipIcon } from "@heroicons/react/24/outline";

export default function BoardItemEdit({
  data,
  setEditable,
  refetch,
  attribute,
}) {
  const navigate = useNavigate();

  const params = useParams();
  const titleInput = useRef();
  const fileInput = useRef();
  const contentInput = useRef();

  const [title, setTitle] = useState(data.title);
  const [content, setContent] = useState(data.itemContent.content);
  const [editor, setEditor] = useState();
  const [upload, setUpload] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const doSubmit = (e) => {
    e.preventDefault();

    if (!titleInput.current.value) {
      titleInput.current.focus();
      return;
    }
    if (content === undefined || content === "") {
      setErrMsg("내용을 입력해주세요.");
      editor.focus();
      return;
    }

    if (attribute.includes("FILE")) {
      let formData = new FormData();
      let fileCount = fileInput.current.files.length;
      for (var x = 0; x < fileCount; x++) {
        formData.append(`file`, fileInput.current.files[x]);
      }
      let param = {
        title: titleInput.current.value,
        content,
        // contentType: `html`,
      };

      for (var key in param) formData.append(key, param[key]);

      setUpload(true);

      axios
        .post(`/api/updateTalkPage/${params.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "bearer " + api.getToken(),
          },
        })
        .then((res) => {
          navigate(-1);
        })
        .catch((res) => {
          window.alert(res);
        });
    } else {
      setUpload(true);

      axios
        .post(
          `/api/updateTalkPage/${params.id}`,
          qs.stringify({
            title: titleInput.current.value,
            content,
            // contentType: `html`,
          }),
          {
            headers: {
              "Content-Type": "Application/json",
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          navigate(-1);
        })
        .catch((res) => {
          window.alert(res);
        });
    }
  };

  const onChange = (e) => {
    setTitle(e.target.value);
  };

  const onCancel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const fileList = data?.itemContent?.files?.files?.map((item) => (
    <tr>
      <td
        className="p-4 border-b border-gray-200 text-sm text-gray-500 cursor-pointer hover:bg-stone-50"
        colSpan="3"
      >
        <PaperClipIcon className="h-4  text-gray-500 mr-2 inline-block group-hover:text-stone-600" />
        {item.name}
      </td>
    </tr>
  ));

  return (
    <>
      <form onSubmit={doSubmit}>
        <label
          htmlFor="title"
          className="block text-sm font-semibold text-slate-600 mb-1"
        >
          제목 수정:
        </label>
        <input
          required
          type="text"
          name="title"
          ref={titleInput}
          className="block w-full px-3 py-2 bg-white
          border border-slate-300 text-sm shadow-sm placeholder-slate-400
          focus:outline-none focus:ring-1 focus:ring-sky-500
          disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 disabled:shadow-none"
          value={title}
          onChange={onChange}
        />

        <br />
        <label
          htmlFor="content"
          className="block text-sm font-semibold text-slate-600 mb-1"
        >
          내용 수정:
        </label>
        <textarea
          ref={contentInput}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
          type="text"
          name="content"
          className="block w-full h-48 px-3 py-2 bg-white 
          border border-slate-300 text-sm shadow-sm placeholder-slate-400
          focus:outline-none  focus:ring-1 focus:ring-sky-500 
          disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 disabled:shadow-none"
        />

        <br />
        <label
          htmlFor="file"
          className="block text-sm font-semibold text-slate-600 mb-1"
        >
          파일 첨부:
        </label>
        <input
          type="file"
          name="file"
          ref={fileInput}
          multiple
          className="block w-full text-sm text-gray-500 
          file:mr-4 file:py-2 file:px-4 file:border-0 
          file:text-sm file:font-semibold file:bg-gray-500 file:text-white
          hover:file:bg-stone-600 hover:file:cursor-pointer"
        />

        <div className="mt-4 flex justify-end space-x-4">
          <SubmitButton text={`완료`} />
          <CancelButton text={`취소`} onClick={onCancel} />
        </div>
      </form>
    </>
  );
}
