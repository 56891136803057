import { Route, Routes } from "react-router-dom";
import ChannelMain from "./ChannelMain";
import BoardMain from "./BoardMain";
import BoardItem from "./BoardItem";
import BoardWrite from "./BoardWrite";
import ChannelList from "./ChannelList";
import ChannelSearch from "./ChannelSearch";

export default function ChannelRouter() {
  return (
    <>
      <Routes>
        <Route path="/" element={<ChannelList />} />
        <Route path="search" element={<ChannelSearch />} />
        <Route path=":channelId/*" element={<ChannelMain />}>
          <Route path=":boardId" element={<BoardMain />} />
          <Route path=":boardId/item/:id" element={<BoardItem />} />
          <Route path=":boardId/write" element={<BoardWrite />} />
        </Route>
      </Routes>
    </>
  );
}
