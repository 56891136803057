import { useRecoilValue } from "recoil";
import { tokenAtom } from "./lib/atom";
import { login_address, server_address } from "./lib/api_address";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./lib/AuthProvider";

export default function HomeLogin() {
  const token = useRecoilValue(tokenAtom);
  const [popup, setPopup] = useState();
  const { login, setUser } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    var addr;
    if (window.location.href.split("/")[2] === `localhost:3000`) {
      addr = `${login_address}/?call=login&client_id=senseworldclient&response_type=code&redirect_uri=http://localhost:3000/auth`;
    } else if (window.location.href.split("/")[2] === `dev.senwd.co.kr`) {
      addr = `${login_address}/?call=login&client_id=senseworldclient&response_type=code&redirect_uri=http://dev.senwd.co.kr/auth`;
    } else {
      addr = `${login_address}/?call=login&client_id=senseworldclient&response_type=code&redirect_uri=http://senwd.co.kr/auth`;
    }

    const popupWin = window.open(
      addr,
      `_blank`,
      `width=400, height=600, top=200, left=200`
    );
    setPopup(popupWin);
  };

  useEffect(() => {
    if (!popup) {
      return;
    }
    const oAuthCodeListener = (e) => {
      // 동일한 Origin 의 이벤트만 처리하도록 제한
      if (e.origin !== window.location.origin) {
        console.log(`origin error`);
        return;
      }
      const { code } = e.data;

      if (code) {
        // console.log(e.data);
        login(code)
          .then(() => {
            setUser();
          })
          .then(() => {
            popup?.close();
            setPopup(null);
          });
      }
    };
    window.addEventListener("message", oAuthCodeListener, false);
    return () => {
      window.removeEventListener("message", oAuthCodeListener);
      popup?.close();
      setPopup(null);
    };
  }, [popup]);

  return (
    <>
      {!token && (
        <>
          <div
            onClick={handleLogin}
            className="p-2 px-12 font-bold rounded-md text-white bg-blue-500 "
            role="button"
            tabIndex={0}
          >
            로그인
          </div>
        </>
      )}
    </>
  );
}
