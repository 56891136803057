export default function Company() {
  return (
    <>
      <div className="w-full sm:w-3/4 sm:mx-auto mt-24">
        <div className="my-16 text-2xl font-bold text-center text-blue-600">
          회사 소개
        </div>
        <div className="p-4 lg:w-1/2 mx-auto">
          Xvision Technology는 시각장애인을 위한 보조 기술을 전문으로,
          시각장애인용 대체 자료와 S/W를 연구 및 개발하는 회사입니다.
          <br /> 여러분을 위해 더 나은, 새로운 세상을 열기 위해 항상 최선을
          다하겠습니다.
          <div className="mt-8">
            주소 : 서울특별시 관악구 은천동 903-2 광림빌딩 2F <br />
            전화 : 02-888-1623 <br />
            팩스 : 02-888-1624 <br />
            이메일 : xvtech@xvtech.com
          </div>
        </div>
      </div>
    </>
  );
}
