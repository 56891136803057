import HomeLogin from "./HomeLogin";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenAtom } from "./lib/atom";

export default function Home() {
  const token = useRecoilValue(tokenAtom);
  return (
    <>
      <div className="w-full">
        <Header />
        <div className=" mx-auto sm:flex w-full lg:w-3/4 pt-24">
          <div className="sm:w-1/2 justify-center items-center flex  ">
            <div>
              {!token ? (
                <HomeLogin />
              ) : (
                <>
                  <Link
                    to={"/channel"}
                    className="block text-xl font-bold text-center text-blue-600 border rounded-lg p-4 border-blue-600 hover:bg-blue-600 hover:text-white"
                  >
                    가입 채널 보기
                  </Link>
                  <Link
                    to={"/channel/search"}
                    className="block text-xl mt-4 font-bold text-center text-blue-600 border rounded-lg p-4 border-blue-600 hover:bg-blue-600 hover:text-white"
                  >
                    채널 검색
                  </Link>
                </>
              )}
            </div>
          </div>
          <div
            className={`sm:w-1/2 w-full sm:mx-auto text-center sm:border-l-blue-500 sm:border-dashed sm:border-l sm:pl-4`}
          >
            <div className={`px-8 p-2 lg:px-0 mx-auto`}>
              <div className={`mb-16 mx-auto `}>
                앱을 설치하면 커뮤니티 서비스인 채널의 더 많은 기능을 사용할 수
                있으며, 시각장애인인 경우 IT로열린도서관에서 제공하는 도서,
                팟캐스트 등과 같은 다양한 컨텐츠를 이용할 수 있습니다.
              </div>

              <a
                href={`https://senwd.co.kr/program/sw_windows.exe`}
                className="block mt-4 text-xl font-bold text-center text-blue-600 border rounded-lg p-4 border-blue-600 hover:bg-blue-600 hover:text-white"
              >
                PC 센스월드 다운로드
              </a>
              <Link
                to={`https://play.google.com/store/apps/details?id=kr.co.xvision.linkany`}
                className="block mt-4 text-xl font-bold text-center text-blue-600 border rounded-lg p-4 border-blue-600 hover:bg-blue-600 hover:text-white"
              >
                안드로이드 링크애니 다운로드
              </Link>
              <Link
                to={`https://play.google.com/store/apps/details?id=kr.co.xvision.senseworld`}
                className="block mt-4 text-xl font-bold text-center text-blue-600 border rounded-lg p-4 border-blue-600 hover:bg-blue-600 hover:text-white"
              >
                안드로이드 리드애니 다운로드
              </Link>
              <Link
                to={`https://apps.apple.com/kr/app/%EB%A7%81%ED%81%AC%EC%95%A0%EB%8B%88/id1492957448`}
                className="block mt-4 text-xl font-bold text-center text-blue-600 border rounded-lg p-4 border-blue-600 hover:bg-blue-600 hover:text-white"
              >
                iOS 링크애니 다운로드
              </Link>
              <Link
                to={`https://apps.apple.com/kr/app/%EB%A6%AC%EB%93%9C%EC%95%A0%EB%8B%88/id521645394`}
                className="block mt-4 text-xl font-bold text-center text-blue-600 border rounded-lg p-4 border-blue-600 hover:bg-blue-600 hover:text-white"
              >
                iOS 리드애니 다운로드
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="fixed bottom-0 left-[50vw] -translate-x-1/2 "></div>
    </>
  );
}
