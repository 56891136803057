import { Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

export const LinkButton = ({ text, address, state = {} }) => {
  return (
    <>
      <Link to={address} state={state}>
        <div className="inline-block px-4 py-2 border border-gray-500  hover:bg-stone-600 text-white bg-gray-500 text-sm font-semibold">
          {text}
        </div>
      </Link>
    </>
  );
};

export const FnButton = ({ text, onClick }) => {
  return (
    <>
      <button
        className="py-2 px-4 
    text-sm  bg-gray-500 text-white border border-gray-500
  hover:bg-stone-600 hover:cursor-pointer box-border"
        onClick={onClick}
      >
        {text}
      </button>
    </>
  );
};

export const SubmitButton = ({ text }) => {
  return (
    <>
      <input
        type="submit"
        value={text}
        className="py-2 px-4
          text-sm  bg-gray-500 text-white
        hover:bg-stone-600 hover:cursor-pointer box-border"
      />
    </>
  );
};

export const ExSubmitButton = ({ text, form }) => {
  return (
    <>
      <button
        className="py-2 px-4 text-sm  bg-gray-500 text-white
      hover:bg-stone-600 box-border"
        form={form}
        type="submit"
      >
        {text}
      </button>
    </>
  );
};

export const CancelButton = ({ text, onClick }) => {
  return (
    <>
      <button
        className="py-2 px-4 border bg-white
text-sm font-semibold border-gray-500 text-gray-500
hover:border-stone-600 hover:text-stone-600 hover:bg-stone-200 box-border"
        onClick={onClick}
      >
        {text}
      </button>
    </>
  );
};

export const DeleteButton = ({ text, onClick }) => {
  return (
    <>
      <button
        className="py-2 px-4 bg-red-500 hover:bg-red-600 box-content text-sm text-white"
        onClick={onClick}
      >
        {text}
      </button>
    </>
  );
};

export const RedButton = ({ text, onClick }) => {
  return (
    <>
      <button
        className="py-2 px-4 border bg-white border-red-500 hover:bg-red-100 box-border text-sm text-red-500"
        onClick={onClick}
      >
        {text}
      </button>
    </>
  );
};

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className="flex hover:bg-stone-200 p-2 text-stone-700  dark:hover:bg-gray-700 dark:hover:text-gray-200"
      onClick={() => {
        navigate(-1);
      }}
    >
      <ChevronLeftIcon className="mt-[0.2rem] h-4 items-center justify-center " />
      <span className="text-sm items-center justify-center font-semibold">
        뒤로 가기
      </span>
    </button>
  );
};

export const FontChangeButton = () => {
  return (
    <button
      className="font-bold bg-gray-400/10 rounded-full py-1 px-3 items-center dark:highlight-white/5 border border-gray-300 "
      onClick={() => {
        if (
          document.documentElement.style.getPropertyValue(`--default-font`) ===
          ""
        ) {
          document.documentElement.style.setProperty(
            `--default-font`,
            "Noto Sans CJK KR"
          );
        } else {
          document.documentElement.style.setProperty(`--default-font`, "");
        }
      }}
    >
      폰트 변경
    </button>
  );
};
