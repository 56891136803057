import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";

export default function ListTestPagination({ data }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  // console.log(currentParams.page, currentParams);
  if (currentParams.page === undefined) currentParams.page = 1;
  // data.number = currentPage + 1
  // data.totalPages = totalPage
  // data.last:true = totalPage = currentPage
  // data.first:true = currentPage = 1
  const createArr = (n) => {
    const iArr = new Array(n);
    for (let i = 0; i < n; i++) iArr[i] = i + 1;
    return iArr;
  };

  const totalList = createArr(data.totalPages);
  const currentStart = Math.floor((Number(currentParams.page) - 1) / 5);
  const currentList = totalList?.slice(currentStart * 5, currentStart * 5 + 5);

  const pageList = currentList?.map((page) => {
    if (page === parseInt(currentParams.page)) {
      return (
        <button
          key={page}
          id={page}
          className="text-stone-400  dark:hover:bg-gray-800 hover:bg-gray-50 relative inline-flex items-center justify-center w-6 sm:px-4 px-3 lg:w-12 sm:py-2 text-xs font-bold  border-b-2 border-b-stone-400 cursor-pointer "
        >
          {page}
        </button>
      );
    } else {
      return (
        <button
          key={page}
          id={page}
          className="text-stone-500 dark:hover:bg-gray-800 dark:hover:text-stone-100 hover:bg-gray-50 relative inline-flex items-center justify-center w-6 sm:px-4 px-3 lg:w-12 sm:py-2 text-xs border-b-2 border-transparent cursor-pointer"
          title={page + " 페이지로"}
          onClick={() => {
            currentParams.page = page;
            setSearchParams(currentParams);
          }}
        >
          {page}
        </button>
      );
    }
  });

  return (
    <nav id="page" className="mt-8 flex justify-center">
      {data.first === false && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            title={"1 페이지로"}
            onClick={() => {
              currentParams.page = 1;
              setSearchParams(currentParams);
            }}
          >
            <ChevronDoubleLeftIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}
      {currentParams.page > 1 && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            // title={Number(currentParams.page) - 1 + " 페이지로"}
            title={"이전 페이지로"}
            onClick={() => {
              currentParams.page = Number(currentParams.page) - 1;
              setSearchParams(currentParams);
            }}
          >
            <ChevronLeftIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}

      {pageList}

      {currentParams.page < data.totalPages && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            // title={Number(currentParams.page) + 1 + " 페이지로"}
            title={"다음 페이지로"}
            onClick={() => {
              currentParams.page = Number(currentParams.page) + 1;
              setSearchParams(currentParams);
            }}
          >
            <ChevronRightIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}
      {data.last === false && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            title={data.totalPages + " 페이지로"}
            onClick={() => {
              currentParams.page = data.totalPages;
              setSearchParams(currentParams);
            }}
          >
            <ChevronDoubleRightIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}
    </nav>
  );
}
