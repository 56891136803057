import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";

import Auth from "./pages/Auth";

import NotFound from "./pages/NotFound";
import ChannelRouter from "./board/ChannelRouter";
import ChannelLayout from "./components/ChannelLayout";
import RequireAuth from "./components/RequireAuth";
import Company from "./pages/Company";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/auth" element={<Auth />} />

        <Route
          path="/company"
          element={
            <ChannelLayout>
              <Company />
            </ChannelLayout>
          }
        />
        <Route
          path="/privacy"
          element={
            <ChannelLayout>
              <Privacy />
            </ChannelLayout>
          }
        />

        <Route
          path="/terms"
          element={
            <ChannelLayout>
              <Terms />
            </ChannelLayout>
          }
        />
        <Route
          path="/channel/*"
          element={
            <ChannelLayout>
              <RequireAuth>
                <ChannelRouter />
              </RequireAuth>
            </ChannelLayout>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
