var login_address;
var api_address;
var server_address;
if (window.location.href.split(`/`)[2] === `senwd.co.kr`) {
  login_address = "https://lgs.senwd.co.kr";
  api_address = "https://api.senwd.co.kr";
  server_address = "https://senwd.co.kr";
} else {
  login_address = "https://dlgs.senwd.co.kr";
  api_address = "https://devapi.senwd.co.kr";
  server_address = "https://dev.senwd.co.kr";
}

export { login_address, api_address, server_address };
