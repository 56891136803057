import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "query-string";
import { useEffect, useRef, useState } from "react";
import api from "../lib/api";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ListTestPagination from "../components/ListTestPagination";

export default function ChannelSearch() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  const keywordParams = searchParams.get("search") || "";
  const pageParams = parseInt(searchParams.get("page")) || 1;
  const inputRef = useRef();

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`ChannelSearch`, keywordParams, pageParams],
    queryFn: () =>
      axios
        .post(
          `/api/searchTalkChannel`,
          qs.stringify({
            search: keywordParams,
            page: pageParams - 1,
            size: 10,
          }),
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => res.data.result),
  });

  function handleSubmit(e) {
    e.preventDefault();
    currentParams.search = inputRef.current.value;
    currentParams.page = 1;
    setSearchParams(currentParams);
  }
  const navigate = useNavigate();

  const ResultList = data?.content?.map((item, index) => (
    <div
      key={index}
      // onClick={() => JoinChannel(item)}
      // onClick={() =>
      //   navigate(`/channel/${item.id}`, {
      //     state: { channelTitle: item.title, admin: item.admin },
      //   })
      // }
      className="w-full border-b border-dashed py-4 items-center text-left hover:bg-gray-100 hover:cursor-pointer"
    >
      <Link
        key={index}
        to={`/channel/${item.id}`}
        state={{ channelTitle: item.title, admin: item.admin }}
      >
        <div className="flex mb-2 items-center">
          <div className="mr-2 text-wrap">{item.title}</div>
          <div className="text-sm text-wrap ml-auto">{item.userCount}명</div>
        </div>
        <div className="text-xs text-gray-500">{item.description}</div>
      </Link>
    </div>
  ));

  return (
    <>
      <div className="w-full sm:w-3/4 sm:mx-auto mt-24">
        <div className="my-16 text-2xl font-bold text-center text-blue-600">
          채널 검색
        </div>
        <div className="text-center">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              ref={inputRef}
              className="border border-gray-500 p-2 rounded-md"
              placeholder="검색어를 입력하세요."
            ></input>
            <button
              type="submit"
              className="bg-blue-500 text-white font-bold p-2 px-8 rounded-md ml-2 hover:bg-blue-600 "
            >
              검색
            </button>
          </form>
        </div>
        <div className="text-center mt-12 mb-8">검색 결과</div>
        <>
          {isLoading ? (
            <div className="container flex items-center align-middle text-center">
              Loading...
            </div>
          ) : error ? (
            <div>Error: {error.message}</div>
          ) : (
            <div>
              <div className="text-center mt-2 px-8">{ResultList}</div>
              <ListTestPagination data={data} />
            </div>
          )}
        </>
      </div>
    </>
  );
}
