import { useRef, useState } from "react";
import api from "../lib/api";
import axios from "axios";

export default function ReplyEdit({
  id,
  commentId,
  content,
  setEditMode,
  refetch,
}) {
  const replyInput = useRef();

  const [replyContent, setReplyContent] = useState(content);
  const doSubmit = (e) => {
    e.preventDefault();
    if (
      !replyInput.current.value ||
      replyInput.current.value.replace(/\s/g, "") === ""
    ) {
      replyInput.current.value = "";
      replyInput.current.focus();
      return;
    }
    let formData = new FormData();
    let param = {
      content: replyContent,
    };
    for (var key in param) formData.append(key, param[key]);

    axios
      .post(`/api/updateTalkComment/${commentId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + api.getToken(),
        },
      })
      .then((res) => {
        setEditMode(false);
        refetch();
      })
      .catch((res) => {
        window.alert(res);
      });
  };

  const onChange = (e) => {
    setReplyContent(e.target.value);
  };

  const onKeyDown = (e) => {
    if (e.shiftKey && (e.keyCode === 13 || e.keyCode === 10)) return;
    // if (e.keyCode === 13 || e.keyCode === 10) doSubmit(e);
  };

  const Cancel = (e) => {
    e.preventDefault();
    setEditMode(false);
  };

  return (
    <>
      <form onSubmit={doSubmit} className="flex">
        <textarea
          required
          ref={replyInput}
          type="text"
          name="content"
          className="px-3 py-2 bg-white dark:bg-black dark:placeholder:text-gray-600 flex-1
          text-sm shadow-sm placeholder-slate-400
          focus:outline-none focus:ring-1 focus:ring-stone-500 dark:focus:ring-gray-600 
          disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 disabled:shadow-none"
          placeholder="댓글 입력"
          value={replyContent}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <input
          type="submit"
          value="수정"
          className="px-4 
          text-sm font-semibold bg-gray-500 border-gray-500 dark:border-gray-700 border dark:bg-gray-700 text-white dark:text-gray-200
          hover:bg-stone-600 hover:cursor-pointer"
        />
        <button
          onClick={Cancel}
          className="px-4 
          text-sm font-semibold border bg-white border-gray-500 dark:bg-gray-700  dark:text-gray-200
        hover:bg-stone-200 hover:cursor-pointer"
        >
          취소
        </button>
      </form>
    </>
  );
}
